import React, { useState, useEffect, useRef } from 'react';
import './App.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { GoogleLogin, googleLogout } from '@react-oauth/google';
import {faCheck, faCircleXmark, faHeadphones, faRecordVinyl, faPlay, faTimes} from '@fortawesome/free-solid-svg-icons';

const is_deployed = true

const server_root = is_deployed ? 'https://testhackai.com' : 'http://localhost:5000'
const fetch_url = server_root + '/api'

const  App = () => {

    const isTesting = true; 

    const exam_id_mapper = {sample_exam_0: 'Sample exam 1'};
    const [examID, setExamID] = useState('');

    const [isGenerating, setIsGenerating] = useState(false);
    const [writingData, setWritingData] = useState(null);
    const [readingTimeLeft, setReadingTimeLeft] = useState(180); // 3 mins timer
    const [timeLeft, setTimeLeft] = useState(1200); // 20 minutes writing timer
    const [writingAnswer, setWritingAnswer] = useState({}); 
    const [showReadingWhileWriting, setShowReadingDuringWriting] = useState(false);
    const [currentScreen, setCurrentScreen] = useState('login');
    const [timerID, setTimerID] = useState(null);
    const [readingTimerID, setReadingTimerID] = useState(null);
    const [wordCount, setWordCount] = useState(0);
    const [selectedExamSection, setSelectedExamSection] = useState(null);
    const [submissionDone, setSubmissionDone] = useState(false);
    const [writingScreenIndex, setWritingScreenIndex] = useState(0);
    const writingScreens = ['writing_ex_select', 'writing_ex1_directions', 'writing_ex1_reading', 'writing_ex1_lecture', 'writing_ex1_writing', 'writing_ex1_overview', 'writing_ex2', 'writing_ex2_overview'];

    useEffect(() => {
        if (selectedExamSection === 'writing') {
            setCurrentScreen(writingScreens[writingScreenIndex]);
        }
    }, [writingScreenIndex]);

    useEffect(() => {
        if (selectedExamSection === 'writing') {
            if (currentScreen === 'writing_ex1_reading') {
                setReadingTimeLeft(180); // 3 minutes
                setTimeout(startReadingTimer, 0);
            }  
            if (currentScreen === 'writing_ex1_writing') {
                setTimeLeft(1200); // 20 minutes
                setWordCount(0);
                setTimeout(startGeneralTimer, 0);
            }
            if (currentScreen === 'writing_ex2') {
                setTimeLeft(600); // 10 minutes
                setWordCount(0);
                setTimeout(startGeneralTimer, 0);
            }
        }

        if (selectedExamSection === 'listening') {            
            if (currentScreen === 'listening_conversation_questions' && !submissionDone) {
                console.log('time should be: ', listeningQuestionsNum * 1.5 * 60);
                setTimeLeft(listeningQuestionsNum * 1.5 * 60); // allow 1.5 minutes per question
                setTimeout(startGeneralTimer, 0);
            }
        }

         
    }, [currentScreen]);


    useEffect(() => {
        currentScreen.includes('directions') && setSubmissionDone(false);
        currentScreen === 'listening_directions' && setListeningExIdx(0);
    }, [currentScreen])

    // reading
    const [readingData, setReadingData] = useState(null);
    const [userAnswers, setUserAnswers] = useState([{},{}]);
    const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
    const [currentReadingIndex, setCurrentReadingIndex] = useState(0);
    const [showTimer, setShowTimer] = useState(true);
    const [readingFeedback, setReadingFeedback] = useState({});

    // Listening
    const [listeningData, setListeningData] = useState(null);
    const [showLConversationDirections, setShowLConversationDirections] = useState(true);
    const [currentListeningQuestionIndex, setCurrentListeningQuestionIndex] = useState(0);
    const [listeningUserAnswers, setListeningUserAnswers] = useState({});
    const [listeningTaskKey, setListeningTaskKey] = useState('');
    const [listeningFeedback, setListeningFeedback] = useState({});
    const [listeningExIdx, setListeningExIdx] = useState(0);
    const [selectedListeningEx, setSelectedListeningEx] = useState(null); // reset to null
    const [selectedListeningQuestion, setSelectedListeningQuestion] = useState(null);
    const [instructionsURL, setInstructionsURL] = useState('');
    const [listeningQuestionsNum, setListeningQuestionNum] = useState(0);
    const [lectureImgURL, setLectureImgURL] = useState('');
    const [contextAudioURL, setContextAudioURL] = useState('');
    const [absQLIndex, setAbsQLIndex] = useState(0);
    const [audioLURLs, setAudioLURLs] = useState({});
    const [transcriptLToggled, setTranscriptLTogged] = useState({});
    const [selectedTranscriptIdx, setSelectedTranscriptIdx] = useState(0);
    const [listOfStartAbsLQIdx, setListOfStartAbsLQIdx] = useState([]);
    const [absIdxToExIdxQIdx, setAbsIdxToExIdxQIdx] = useState([]); // maps abs indexes to 
    
    // speaking
    const [speakingData, setSpeakingData] = useState(null);
    const [speakingExamID, setSpeakingExamID] = useState(null);
    const [speakingExIdx, setSpeakingExIdx] = useState(0);
    const [selectedSAudioIdx, setSelectedSAudioIdx] = useState(0);
    const [speakingScreenIndex, setSpeakingScreenIdx] = useState(0);
    const [speakingIsRecording, setSpeakingIsRecording] = useState(false);
    const [mediaRecorder, setMediaRecorder] = useState(null);
    const preparation_times = [15, 30, 30, 20];
    const speaking_times = [45, 60, 60, 60];
    const reading_time = 45 // seconds
    const recordedChunksRef = useRef([]);
    const [paused, setPaused] = useState(false);
    const [isFirstTime, setIsFirstTIme] = useState(true);
    const[userTranscripts, setUserTranscripts] = useState(null);

    useEffect(()=>{
        speakingExIdx > 0 && setSpeakingScreenIdx(1);
        console.log('speaking ex idx changed to: ', speakingExIdx);
    }, [speakingExIdx])

    useEffect(()=>{
        !isFirstTime && paused && clearTimerIntervals();
        !isFirstTime && !paused && startGeneralTimer();
        isFirstTime && setIsFirstTIme(false);

    }, [paused])


    const speakingScreensOrder = [
        'speaking_directions', 
        'speaking_task_directions', 
        'speaking_reading',
        'speaking_listening',
        'speaking_question', 
        'speaking_prep',
        'speaking_record',
        'speaking_exercise_switch',
        'speaking_overview'
    ];
    
    
    useEffect(() => {
        if (selectedExamSection === 'speaking') {
            if (timeLeft <= 0) {
                clearTimerIntervals(); // stop the timer when time is zero.
                if (currentScreen === 'speaking_prep') {
                    setTimeLeft(speaking_times[speakingExIdx]); 
                    setSpeakingScreenIdx(p=>p+1);

                } else if (currentScreen === 'speaking_record') {
                    if (mediaRecorder) {
                        stopRecording();
                        setSpeakingScreenIdx(p => p+1);
                        ;
                    }; // stop recording if not stoped earlier
                    // go to next exercise
                } else if (currentScreen === 'speaking_reading') {
                    setSpeakingScreenIdx(p=>p+1);
                }
                
            }
        }
    }, [timeLeft, speakingExIdx, selectedExamSection]);

    useEffect(() => {
        currentScreen === 'reading_start' && startGeneralTimer();
        currentScreen === 'speaking_question' && setTimeLeft(preparation_times[speakingExIdx]);
        ((speakingExIdx === 0 || speakingExIdx === 3) && currentScreen === 'speaking_reading') && setSpeakingScreenIdx(p=>p+1);
        (speakingExIdx === 0 && currentScreen === 'speaking_listening') && setSpeakingScreenIdx(p=>p+1);  
        if (currentScreen === 'speaking_reading') {
            setTimeLeft(reading_time);
            setTimeout(startGeneralTimer, 100); // delay so that it waits until reading time is set 
        }
         if (currentScreen === 'speaking_exercise_switch' && speakingExIdx === 3) {
            genSpeakingAudioTranscripts();
            setSpeakingScreenIdx(p=>p+1);
        } 
    }, [currentScreen])

    useEffect(() => {
        const idx = Math.min(speakingScreenIndex, speakingScreensOrder.length - 1);
        if (selectedExamSection === 'speaking') {
            setCurrentScreen(speakingScreensOrder[idx]);
        }  

    }, [speakingScreenIndex]);
    

    useEffect(()=> {
        if (submissionDone && selectedExamSection === 'listening') {
            setCurrentScreen('listening_overview')
        };
        if (submissionDone && selectedExamSection === 'reading') {
            setCurrentScreen('reading_overview')
        }
    }, [submissionDone]);

    useEffect(()=>{
    
        if (listeningData) {
            const prevQLen = listeningData.map(ex=>ex.questions?.length)
                .slice(0, listeningExIdx)
                .reduce((suma, l) => suma + l, 0);
            const absIdx = prevQLen + currentListeningQuestionIndex;
            setAbsQLIndex(absIdx);
        }   
    }, [listeningData, listeningExIdx, currentListeningQuestionIndex])

    useEffect(()=>{
        selectedListeningEx && setSelectedListeningQuestion(selectedListeningEx?.questions[currentListeningQuestionIndex]);
    }, [selectedListeningEx, currentListeningQuestionIndex])



    useEffect(()=>{
        if (selectedListeningQuestion && selectedListeningQuestion.type === 'context_understanding') {
            setContextAudioURL(`${server_root}/data/listening/${listeningTaskKey}/question_${currentListeningQuestionIndex}_context.mp3`);
        };
    }, [selectedListeningQuestion]);

    useEffect(()=>{
        if (listeningData) {
            const outIdxList = []; 
            const mappingList = [];           
            for (let idx = 0 ; idx < listeningQuestionsNum; idx ++) {
                const {listeningExIdx, currentListeningQuestionIndex} = getExAndQIndex(idx, listeningData);
                currentListeningQuestionIndex === 0 && outIdxList.push(idx);
                mappingList.push([listeningExIdx, currentListeningQuestionIndex]);
                
            }
            console.log('listofStartAbsLQIdx: ', outIdxList);
            setListOfStartAbsLQIdx(outIdxList);
            setAbsIdxToExIdxQIdx(mappingList);
            console.log('mapping abs idx To exIdx and QIdx: ', mappingList )
        }         
    }, [listeningQuestionsNum, listeningData])

    useEffect(() => {
        if (listeningData) {
            const type = listeningData[listeningExIdx]?.type;
            const task_id = listeningData[listeningExIdx]?.task_id
            setLectureImgURL(`${server_root}/data/listening/${type}/${task_id}/lecture_img.jpg`)
            setListeningTaskKey(`${type}/${task_id}`);
            setSelectedListeningEx(listeningData[listeningExIdx]);
            console.log('Listening ex being set as: ', listeningData[listeningExIdx]);
            setInstructionsURL(`${server_root}/data/listening/${type}/${task_id}/instructions.mp3`);
            
            !submissionDone && setShowLConversationDirections(true);

            const dicto = listeningData.reduce((dicto, v, idx) => {
                const audioLink = `${server_root}/data/listening/${v.type}/${v.task_id}/conversation.mp3`
                dicto[idx] = audioLink;
                return dicto;
            }, {});
            setAudioLURLs(dicto);

        }

        // selectedExamSection === 'listening' && setCurrentScreen((listeningExIdx === 0 && !submissionDone) ? 'directions' : (submissionDone ? 'listening_conversation_questions' : 'listening_conversation_start'));

    }, [listeningExIdx, listeningData]);



    const startRecording = () => {
        recordedChunksRef.current = [];
        navigator.mediaDevices
          .getUserMedia({ audio: true })
          .then((stream) => {
            const options = { mimeType: 'audio/webm; codecs=opus' };
            if (!MediaRecorder.isTypeSupported(options.mimeType)) {
                options.mimeType = 'audio/webm';
            }
            const recorder = new MediaRecorder(stream, options);
            setMediaRecorder(recorder);
    
            recorder.ondataavailable = (e) => {
                if (e.data.size > 0) {
                  console.log('Data available:', e.data.size);
                  recordedChunksRef.current.push(e.data);
                } else {
                  console.log('No data available');
                }
              };
    
            recorder.onstop = () => {
                console.log('Recording stopped');
                console.log('Recorded chunks:', recordedChunksRef.current);
                const blob = new Blob(recordedChunksRef.current, { type: 'audio/webm' });
                console.log('Blob size:', blob.size);
                saveSpeakingAudio(blob);
                recordedChunksRef.current = [];
            };
    
            recorder.start();
            setSpeakingIsRecording(true);
          })
          .catch((err) => {
            console.error('Error accessing microphone', err);
          });
      };
    
      const stopRecording = () => {
        if (mediaRecorder) {
          mediaRecorder.stop();
          mediaRecorder.stream.getTracks().forEach((track) => track.stop());
          setMediaRecorder(null);
          setSpeakingIsRecording(false);
        }
      };
    
      const saveSpeakingAudio = async (audioBlob) => {
        const formData = new FormData();
        formData.append('audio', audioBlob, `task${speakingExIdx + 1}.webm`);
        formData.append('examID', speakingExamID);
        formData.append('task_idx', speakingExIdx);
    
        const response = await fetch(`${fetch_url}/save_speaking_audio`, {
          method: 'POST',
          body: formData,
        })
        const data = await response.json();
        console.log(data.success ? `speaking audio for exam ${speakingExamID}, task ${speakingExIdx + 1} correctly saved` : `ERROR on saving speaking data for exam ${speakingExamID}, task ${speakingExIdx + 1}`);
      };



    const  getExAndQIndex = (absQLIndex, listeningData) =>  {
        let accumulatedLength = 0;
    
        for (let exIdx = 0; exIdx < listeningData.length; exIdx++) {
            const questionsLength = listeningData[exIdx].questions?.length || 0;
            
            if (accumulatedLength + questionsLength > absQLIndex) {
                const qIndex = absQLIndex - accumulatedLength;
                return { listeningExIdx: exIdx, currentListeningQuestionIndex: qIndex };
            }            
            accumulatedLength += questionsLength;
        }        
        return { listeningExIdx: -1, currentListeningQuestionIndex: -1 };
    };

    const getWritingExam = async() => {
        const response = await fetch(`${fetch_url}/get_random_writing_exam`, {
            method: 'GET'
        });
        const data = await response.json();
        setWritingData(data);
        // move to exercise type selection. 
    }

    const fetchExam = async(exam_id) => {
        setIsGenerating(true);
        const response = await fetch(`${fetch_url}/get_complete_exam`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({exam_id: exam_id}),
          })
          setExamID(exam_id);
          const data = await response.json();
          
          setListeningData(data.listening_data);
          const questions_num = data.listening_data.map(ex => ex.questions.length).reduce((acc, curr) => acc + curr, 0);
          setListeningQuestionNum(questions_num);
          setListeningExIdx(0);
          setReadingData(data.reading_data);
          setWritingData(data.writing_data);
          setSpeakingData(data.speaking_data.data);
          setSpeakingExamID(data.speaking_data.task_id);
          setIsGenerating(false);
    }

    
    const genWritingEx = async (selectedTopic, exNum) => {
        setIsGenerating(true);
        const response = await fetch(`${fetch_url}/gen_writing_ex`, {
            method: 'POST',
            headers: {'Content-Type': 'application/json'}, 
            body: JSON.stringify({num: exNum, topic: selectedTopic})
        });

        const data = await response.json();
        if (exNum === 1){
            data.reading = formatReadingText(data.reading);
            setCurrentScreen('writing_ex1_start');
            setTimeLeft(1200); // 20 minutes
        } 
        else {
            setCurrentScreen('writing_ex2');
            setTimeLeft(600); // 10 minutes
            startGeneralTimer();
        }
        setIsGenerating(false);
        setWritingData(data);

    };

    const genSpeakingAudioTranscripts = async () => {
        setIsGenerating(true);
        const response = await fetch(`${fetch_url}/gen_speaking_audio_transcripts`, {
            method: 'POST',
            headers: {'Content-Type': 'application/json'},
            body: JSON.stringify({examID: speakingExamID})
    });
    const data = await response.json();
    setIsGenerating(false);
    setUserTranscripts(data);
};

    const fakeLSubmission = async () => {
        const response = await fetch(`${fetch_url}/fake_submission_L`, {
            method: 'POST',
            headers: {'Content-Type': 'application/json'}, 
            body: JSON.stringify({exercises: listeningData})
        });

        const data = await response.json();
        setListeningUserAnswers(data.uans);
        console.log('user Ans fixed', data.uans);
        setListeningFeedback(data.fb);
        setSubmissionDone(true);
    }


    const genReadingEx = async(selectedTopic) => {
        setIsGenerating(true);
        const response = await fetch(`${fetch_url}/gen_reading_ex`, {
            method: 'POST',
            headers: {'Content-Type': 'application/json'}, 
            body: JSON.stringify({topic: selectedTopic})
        });

        const data = await response.json();
        setReadingData(data);
        setIsGenerating(false);
        setCurrentScreen('reading_start');
        setTimeLeft(18*60); 

    };



    const getListeningExam = async() => {
        const response = await fetch(`${fetch_url}/get_listening_test`, {
            method: 'GET'
        });
        const data = await response.json(); // list[dict]
        setListeningData(data);
        const questions_num = data.map(ex => ex.questions.length).reduce((acc, curr) => acc + curr, 0);
        setListeningQuestionNum(questions_num);
        setTimeLeft(questions_num * 1.5 * 60); // allow 1.5 minutes per question 
        setListeningExIdx(0);
    }

    
    const genListeningExam = async() => {
        setIsGenerating(true);
        const response = await fetch(`${fetch_url}/generate_listening_test`, {
            method: 'GET'
        });
        const data = await response.json(); // list[dict]
        setListeningData(data);
        const questions_num = data.map(ex => ex.questions.length).reduce((acc, curr) => acc + curr, 0);
        setListeningQuestionNum(questions_num);
        setTimeLeft(questions_num * 1.5 * 60); // allow 1.5 minutes per question 
        setListeningExIdx(0);
        setIsGenerating(false);
    }


    const getSpeakingExam = async(generate=false) =>{
        generate && setIsGenerating(true);
        const response = await fetch(`${fetch_url}/${generate ? 'gen' : 'get'}_speaking_test`, {
            method: 'GET'
        });
        const data = await response.json(); // list[dict]
        setSpeakingData(data.data);
        setSpeakingExamID(data.task_id);
        setSpeakingExIdx(0);
        setIsGenerating(false);
    };


    const getTwoRandomReadings = async() => {
        const response = await fetch(`${fetch_url}/get_2_random_readings`, {
            method: 'GET'
        });
        const data = await response.json(); // (data.questions_num: int, data.readings: list[dicts])
        setReadingData(data);
        setCurrentScreen('reading_start');
        setTimeLeft(36*60); 
    };

    

    const getRandomWritingEx = async (ex_num) => {
        const response = await fetch(`${fetch_url}/get_random_writing_ex`, {
            method: 'POST',
            headers: {'Content-Type': 'application/json'}, 
            body: JSON.stringify({num: ex_num})
        });
        const data = await response.json();
        
        if (ex_num === 1) {
            data.reading = formatReadingText(data.reading);
            setCurrentScreen('writing_ex1_start');
            setTimeLeft(1200);
        } else {
            setCurrentScreen('writing_ex2');
            setTimeLeft(1200);
            startGeneralTimer();
        }
        data.answer = formatReadingText(data.answer);
        setWordCount(0);
        setWritingData(data);
        setIsGenerating(false);
    }


    const startReadingTimer = () => {
        if (readingTimerID) {
            clearInterval(readingTimerID);
            setReadingTimerID(null);
        }
        const localTimerID = setInterval(() => {
            setReadingTimeLeft((prevTime) => {
                if (prevTime < 1) {
                    clearInterval(localTimerID);
                    setReadingTimerID(null);
                    setWritingScreenIndex(p=>p+1);
                    return 0;
                }
                return prevTime - 1;
            });
        }, 1000); // update every 1000ms i.e. 1s
        setReadingTimerID(localTimerID);
    };

    const startGeneralTimer = () => {
        if (timerID) {
            clearInterval(timerID);
            setTimerID(null);
        }
        const localTimerID = setInterval(() => {
            setTimeLeft((pt) => pt - 1);
        }, 1000);
        setTimerID(localTimerID);
    };

    const clearTimerIntervals = () => {
        if (timerID) {
            clearInterval(timerID);
            setTimerID(null);
        }
        if (readingTimerID) {
            clearInterval(readingTimerID);
            setReadingTimerID(null);
        }
    };

    const scrollToParagraph = (pIdx) => {
        const pEl = document.getElementById( pIdx !== 0 ? `paragraph-${pIdx}` : 'reading-header');
        pEl && pEl.scrollIntoView( {behaviour: 'smooth', block: 'end'} );
    };

    const handleNextQuestion = () => {
        // scroll to next paragraph focus if it exists. 
        let nextQIdx = currentQuestionIndex + 1;
        let nextReadingIdx = currentReadingIndex;

        const questionsLen = readingData.readings[currentReadingIndex].questions.length; 
        if (nextQIdx >  questionsLen - 1) {
            nextQIdx = currentReadingIndex === 0 ? 0 : questionsLen - 1;
            nextReadingIdx = currentReadingIndex === 0 ? 1 : 1;
        } 
        setCurrentQuestionIndex(nextQIdx);
        setCurrentReadingIndex(nextReadingIdx);
        const target_paragraph = readingData.readings[nextReadingIdx].questions[nextQIdx]?.idx_target_paragraph;
        scrollToParagraph(target_paragraph);
    };

    const handlePreviousQuestion = () => {
        // scroll to next paragraph focus if it exists. 
        let nextQIdx = currentQuestionIndex - 1;
        let nextReadingIdx = currentReadingIndex;
        if (nextQIdx < 0) {
            nextQIdx = currentReadingIndex === 1 ? readingData.readings[0].questions.length - 1 : 0;
            nextReadingIdx = currentReadingIndex === 1 ? 0 : 0;
        } 
        setCurrentQuestionIndex(nextQIdx);
        setCurrentReadingIndex(nextReadingIdx);
        const target_paragraph = readingData.readings[nextReadingIdx].questions[nextQIdx]?.idx_target_paragraph;
        scrollToParagraph(target_paragraph);
    };


    const submitReadingAnswers = async () => {
        setIsGenerating(true);
        setSubmissionDone(true);
        const response = await fetch(`${fetch_url}/score_reading_answers`, {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({u_answer: userAnswers, time_left: timeLeft, task_id: readingData.readings.map(item=>item.task_id)}),
        });
        const data = await response.json();
        setReadingFeedback(data);
        clearTimerIntervals();
        setIsGenerating(false);
    };

    const submitListeningAnswers = async () => {
        setSubmissionDone(true);
        const response = await fetch(`${fetch_url}/score_listening_answers`, {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({u_answer: listeningUserAnswers, time_left: timeLeft}),
        });
        const data = await response.json();
        setListeningFeedback(data);
        setSubmissionDone(true);
        clearTimerIntervals();
    };

    

    const handleOrganizationChange = (question, qIdx, oIdx, gIdx) => {
        setUserAnswers((prevAnswers) => {
            // Ensure we have an array for this question's answers
            const readingAnswers = [...prevAnswers]
            const updatedAnswers = {...readingAnswers[currentReadingIndex]}
    
            if (!updatedAnswers[qIdx]) {
                // Initialize groups if not already done
                updatedAnswers[qIdx] = [[], []];
            } else {
                // Deep copy is needed for both the object and nested arrays
                updatedAnswers[qIdx] = updatedAnswers[qIdx].map(g => [...g]);
            }
    
            const group = updatedAnswers[qIdx][gIdx];
            const otherGroupIndex = gIdx === 0 ? 1 : 0;
            const otherGroup = updatedAnswers[qIdx][otherGroupIndex];
    
            // Remove the option from the other group if it exists
            if (otherGroup.includes(oIdx)) {
                updatedAnswers[qIdx][otherGroupIndex] = otherGroup.filter((i) => i !== oIdx);
            }
    
            // Add or remove the option in the selected group
            if (group.includes(oIdx)) {
                // If the option is already in the group, remove it (toggle off)
                updatedAnswers[qIdx][gIdx] = group.filter((i) => i !== oIdx);
            } else {
                // Ensure the user does not exceed the allowed number of selections
                if (group.length < question.answer[gIdx].length) {
                    updatedAnswers[qIdx][gIdx] = [...group, oIdx];
                }
            }
            readingAnswers[currentReadingIndex] = updatedAnswers;
            return readingAnswers;
        });
    };

    const CorrectIcon = () => <span><FontAwesomeIcon icon={faCheck} style={{color: 'green', marginLeft: '6px'}}/></span>; 
    const IncorrectIcon = () => <span><FontAwesomeIcon icon={faTimes} style={{color: 'red', marginLeft: '6px'}}/></span>;

    const handleAnswerChange = (question_idx, option_idx, isCheckbox = false) => {
        // DOING: user_answer is an array of reading objects// 
        const reading_answers = [...userAnswers];
        const updated_answers = {...reading_answers[currentReadingIndex]} || {}; // dict[idx: question_answer]
        let uAnswer_q = updated_answers[question_idx];
        // option_idx: is the newly marked option
        if (isCheckbox) {
            // if isCheckbox uAnswer_q is a list[int]
            uAnswer_q = uAnswer_q ? [...uAnswer_q] :  [];
            if (uAnswer_q.includes(option_idx)) {
                uAnswer_q = uAnswer_q.filter(o => o !== option_idx);                
            } else {
                // select max 3 options on summary
                if (uAnswer_q.length < 3) {
                    uAnswer_q.push(option_idx);
                }
            }
        
        } else {
            //uAnswer_q: int
            uAnswer_q = option_idx 
        }
        reading_answers[currentReadingIndex] = {...reading_answers[currentReadingIndex], [question_idx]: uAnswer_q}
        setUserAnswers(reading_answers);
    }

    const handleListeningAnswerChange = (optionIdx) => {
        const currentAnswers = listeningUserAnswers[listeningTaskKey] || [];
        const question = selectedListeningEx.questions[currentListeningQuestionIndex];
        const maxSelectableOptions = question.answer.length;

        let updatedAnswers = currentAnswers[currentListeningQuestionIndex] || [];
        
        if (updatedAnswers.includes(optionIdx)) {
            updatedAnswers = updatedAnswers.filter((ans) => ans !== optionIdx);
        } else if (updatedAnswers.length < maxSelectableOptions) {              
            updatedAnswers = [...updatedAnswers, optionIdx];
        } else if (updatedAnswers.length === maxSelectableOptions && maxSelectableOptions === 1) {
            updatedAnswers = [optionIdx];
        }
        const newAnswers = [...currentAnswers];
        newAnswers[currentListeningQuestionIndex] = updatedAnswers;
        setListeningUserAnswers(pv => ({...pv, [listeningTaskKey]: newAnswers}));
        console.log('question:, ',currentListeningQuestionIndex, 'answer [0] changed to: ', updatedAnswers[0]);
    };


    const handleTableAnswerChange = (rowIdx, colIdx) => {
        const currentAnswers = listeningUserAnswers[listeningTaskKey] || [];
        let updatedAnswers = currentAnswers[currentListeningQuestionIndex] || [];
    
        const answerTuple = [rowIdx, colIdx];
    
        // Add or remove the selection based on whether it's already selected
        if (updatedAnswers.some((ans) => ans[0] === rowIdx && ans[1] === colIdx)) {
            updatedAnswers = updatedAnswers.filter((ans) => !(ans[0] === rowIdx && ans[1] === colIdx));
        } else {
            updatedAnswers = [...updatedAnswers, answerTuple];
        }
    
        const newAnswers = [...currentAnswers];
        newAnswers[currentListeningQuestionIndex] = updatedAnswers;
        setListeningUserAnswers((prev) => ({ ...prev, [listeningTaskKey]: newAnswers }));
    };

    // Function to move to the next question
    const handleNextListeningQuestion = () => {
        if (currentListeningQuestionIndex < selectedListeningEx.questions.length - 1) {
            setCurrentListeningQuestionIndex(currentListeningQuestionIndex + 1);
        } else {
            // It is last question
            if (listeningExIdx === listeningData.length - 1) {
                // if last exercise & last question: submit
                submitListeningAnswers();
            } else {
                setListeningExIdx( pv => pv+1);
                setCurrentListeningQuestionIndex(0);
            }
        }
};

const handlePrevListeningQuestion = () => {
    setCurrentListeningQuestionIndex(
        pv => {
            let next_q_idx;
            if (pv === 0) {
                next_q_idx = listeningExIdx > 0 ? listeningData[listeningExIdx - 1].questions.length - 1: 0;
               
            } else {
                next_q_idx = pv - 1;
            }
            return next_q_idx;
        }
    )
    currentListeningQuestionIndex === 0 && setListeningExIdx(pidx => Math.max(0,pidx-1));
};


    const renderQuestion = (question, questionIndex) => {
        const feedback = readingFeedback.feedback?.[currentReadingIndex]?.find(f => f.question === question.question);

        const renderOptions = (option, idx, isCheckbox = false) => {

            // marked == selected by the user. isCorrect == is GT correct.
            const isMarked = userAnswers[currentReadingIndex][questionIndex] === idx || (Array.isArray(userAnswers[currentReadingIndex][questionIndex]) && userAnswers[currentReadingIndex][questionIndex].includes(idx))
            const isCorrect = question.answer === idx || (Array.isArray(question.answer) && question.answer.includes(idx));
            const isUserCorrect = isMarked && isCorrect;
            const isUserIncorrect = isMarked && !isCorrect;
            
            return (
                <div key={idx} style={{color: !isMarked && isCorrect && submissionDone ? 'red' : 'inherit'}}>
                    <input
                        type = {isCheckbox ? 'checkbox' : 'radio'}
                        // name should be the same for radio exclusive options 
                        name = {`question-${questionIndex}-${isCheckbox ? idx : ''}`}
                        value = {idx}
                        checked = {isMarked}
                        onChange={() => handleAnswerChange(questionIndex, idx, isCheckbox)}
                        disabled = {submissionDone}
                    />
                    <label>{String.fromCharCode(97 + idx)}: {option}</label>
                    {submissionDone && (isUserCorrect && <CorrectIcon />)}
                    {submissionDone && (isUserIncorrect && <IncorrectIcon />)}                    
                </div>
            );
        }


        switch (question.type) {
            case 'normal':
            case 'sentence_insertion':
                return (
                    <div> 
                        <p>{questionIndex + 1}: {question.question}</p>
                        {feedback && <div><p>score: {feedback.points}/{feedback.max_points}</p></div>}  
                        {question.context && <div><p>{question.context}</p></div>} 
                        {question.options && question.options.map((option, idx) => renderOptions(option, idx))}
                        {feedback &&  <div className='reasoning-text'><p>{feedback.answer_reasoning}</p></div>}
                    </div>
                );
            case 'summary':
                return (
                    <div> 
                        <p>{questionIndex + 1}: {question.question}</p>
                        {feedback && <div><p>score: {feedback.points}/{feedback.max_points}</p></div>}   
                        {question.options && question.options.map((option, idx) => renderOptions(option, idx, true))}
                        {feedback &&  <div className='reasoning-text'><p>{feedback.answer_reasoning}</p></div>}
                    </div>
                );
            case 'organization':
                return (
                    <div>
                        <p>{questionIndex + 1}: {question.question}</p>
                        {feedback && <div><p>score: {feedback.points}/{feedback.max_points}</p></div>}   
                        {question.groups.map((g, gIdx) => (
                                <div key = {gIdx}>
                                <p><strong>G{gIdx+1}</strong>: {g} - (select {question.answer[gIdx].length} options)</p>
                                </div>
                        ))}
                        <table className='styled-table'>
                            <thead>
                                <tr>
                                    <th>Options</th>
                                    <th>G1</th><th>G2</th>
                                    {feedback && <>
                                        <th> G1</th>
                                        <th> G2</th>
                                    </>}
                                </tr>
                            </thead>
                            <tbody>
                                {question.options && question.options.map((option, oIdx) => (
                                    <tr key={oIdx}>
                                        <td>{`${String.fromCharCode(97 + oIdx)}: ${option}`}</td>
                                        {question.groups.map((_, gIdx) => (
                                            <td key = {gIdx}>
                                            <input
                                                type="checkbox"
                                                name={`group-${oIdx}`}
                                                checked={(userAnswers[currentReadingIndex][questionIndex]?.[gIdx] || []).includes(oIdx)}
                                                onChange={() => handleOrganizationChange(question, questionIndex, oIdx, gIdx)}
                                                disabled={submissionDone}
                                            />
                                            </td>
                                        ))}

                                    {feedback && feedback.answer.map((ans, gIdx) => (
                                    <td key={gIdx}>
                                    {ans.includes(oIdx) ? <CorrectIcon /> : <IncorrectIcon />}
                                    </td>
                                    ))}
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    {feedback &&  <div className='reasoning-text'><p>{feedback.answer_reasoning}</p></div>}
                    </div>
                );
            default:
                return null;
        }
    };

    const getTimerClass = (timeLeft) => {
        if (timeLeft <= 0) {
            return 'timer-block negative-time';
        } else if (timeLeft <= 60) {
            return 'timer-block low-time';
        } else {
            return 'timer-block';
        }
    };



    const formatReadingText = (text) => {
        return text.replace(/\n/g, '<br/>');
    };

    const resetToHome = () => {
        // TODO: remove refresh late 
        window.location.reload();
        setCurrentScreen('app_home');
        setIsGenerating(false);
        setReadingTimeLeft(180);
        setTimeLeft(1200);
        setWritingAnswer({});
        clearTimerIntervals();
        setSubmissionDone(false);
        setCurrentQuestionIndex(0);
        setCurrentReadingIndex(0);
        setReadingFeedback({});
        setUserAnswers([{},{}]);
        setShowTimer(true);
        setShowLConversationDirections(true);
        setSubmissionDone(false);
        setListeningFeedback({});
        setListeningUserAnswers({});
        setCurrentListeningQuestionIndex(0);
        setListeningExIdx(0);
        setListeningData(null)
        clearTimerIntervals();
        setWritingData(null);
        setWritingAnswer({});
        // speaking
        setSpeakingData(null);
        setSpeakingExIdx(0);
        setSpeakingScreenIdx(0);
        setSpeakingIsRecording(false);
        setSpeakingExamID('');
        recordedChunksRef.current = [];
    };

    const onSubmitWriting = async (ex_num) => {
        // save the student answer, time
        const response = await fetch(`${fetch_url}/save_user_answer_writing`, {
            method: 'POST',
            headers: {'Content-Type': 'application/json'}, 
            body: JSON.stringify({task_id: writingData[`ex${ex_num}`]?.task_id, ex_num: ex_num, user_answer: writingAnswer[ex_num], time_left: timeLeft})
        });
        const data = await response.json();

        if (data.success) {
            setCurrentScreen(`writing_ex${ex_num}_overview`);
            clearTimerIntervals();
            setSubmissionDone(true);
        }


    };

    const handleInputChange = (e, ex_num) => {
        const user_text = e.target.value;
        setWritingAnswer(pv => ({...pv, [ex_num]: user_text}));
        setWordCount(user_text.split(/\s+/).filter(Boolean).length);
    }


    const testIncreaseDeltaLeft = ()=>{
        setTimeLeft(p=> p - 5 * 60);
    };


    const onCreateReadingEx = () => {
        // loading screen
        setCurrentScreen('topicSelect');

    }

    

    const onSelectTopic = (topic) => {
        if (selectedExamSection === 'writing') {
        } else if (selectedExamSection === 'reading') {
            genReadingEx(topic);
        }

    }

    const onSelectExamSection = (section) => {
        setCurrentScreen(section !== 'writing' ? `${section}_directions` : 'writing_ex_select');
        setSelectedExamSection(section);
    }

    const  FormatTime = ({timeLeft})  => {
        return (
            <>
                {timeLeft < 0 ? '-' : ''}
                {Math.floor(Math.abs(timeLeft) / 60)}:
                {(Math.abs(timeLeft) % 60).toString().padStart(2, '0')}
            </>
        );
    }

    const FormatIconListening = () => {
        return (
            <>
            {(submissionDone && listeningFeedback?.fb?.length > 0) && (listeningFeedback.fb[absQLIndex].is_correct ? <CorrectIcon/> : <IncorrectIcon/>)}
            </>
        )
    }

    const CloseButton = ()  => {
        return (
            <>        
                <button className='close-button' onClick={resetToHome}><span><FontAwesomeIcon icon={faCircleXmark}/></span></button>
            </>
        );
    }


    const ListeningOverview = ({ feedback, listeningData, onSelectQ }) => {
        return (
            <div className='listening-overview'>
                
                <h2>Listening Overview</h2>
                <div className='scores-time-listening'>
                    <span>Score: {feedback.scaled_score}/{30}</span>
                    <span>Points: {feedback.raw_score}/{feedback.max_score}</span>
                    <span><FormatTime timeLeft={submissionDone ? listeningFeedback?.time_left : timeLeft}/></span>
                </div>
                <div>
                    {false && <button>Download report</button>}
                    <button className='exercise-button' onClick={resetToHome}>Exit</button>
                    <button className='exercise-button' onClick={()=>onSelectExamSection('speaking')}>Go speaking</button>
                    <button onClick={()=>setCurrentScreen('transcripts')} className='exercise-button'>Transcripts</button>
                </div>
                <div className="listening-questions-grid">
                    {feedback.fb?.map((fb, absQIdx) => (
                        <div className= {`listening-question-card ${listOfStartAbsLQIdx.includes(absQIdx) ? "group-start" : ""}`} key={absQIdx}>
                            <div className="listening-section-header">
                                {listOfStartAbsLQIdx.includes(absQIdx) && (
                                    <span className="section-type">
                                        {absIdxToExIdxQIdx[absQIdx][0] + 1}. {listeningData[absIdxToExIdxQIdx[absQIdx][0]].type === 'conversation' ? 'Conversation' : 'Lecture'}
                                    </span>
                                )}
                                <div className="question-info">
                                    <span>{absQIdx + 1}: {fb.is_correct ? <CorrectIcon /> : <IncorrectIcon />}</span>
                                    <button onClick={() => onSelectQ(absIdxToExIdxQIdx[absQIdx][0], absIdxToExIdxQIdx[absQIdx][1])}
                                    >
                                        Go
                                    </button>
                                </div>
                            </div>                            
                        </div>
                    ))}
                </div>
            </div>
        );
    };


    return (
        <div className="App">

            {currentScreen === 'login' && (
                <div className='speaking-reading-block'>
                    <GoogleLogin
                        // ux_mode="redirect"
                        onSuccess={credentialResponse => {

                            fetch(`${fetch_url}/login`, {
                                method: "POST",
                                headers: {"Content-Type": "application/json"},
                                body: JSON.stringify({token: credentialResponse.credential})
                            })
                            .then(response => response.json())
                            .then(data => {
                                // setSessionToken(data.token);
                                // setUserEmail(data.email);
                                // setUsername(data.name);
                                // move to interest tab
                                data.success && alert('pito is working!!! XD')
                            });  

                        }}
                        onError={() => {
                            console.log('Login Failed');
                        }}
                    />
                </div>
            )}

            {currentScreen === 'app_home' && (
                <>
                <h2>Home</h2>
                <div className='buttons-home'>
                    <button 
                    className='exercise-button'
                    onClick={()=>setCurrentScreen('exam_id_selection')}
                    >Practise 🧾</button>
                    <button className='exercise-button'>Shop </button>
                </div>
                <CloseButton/>
                </>
            )}

            {currentScreen === 'exam_id_selection' && (
                <>
                <CloseButton/>
                <h2>Select exam</h2>
                <div className='buttons-home'>
                    <button 
                        className='exercise-button'
                        onClick={()=>{
                            fetchExam('sample_exam_0');
                            setCurrentScreen('select_exam_section');
                        }}
                        >Sample exam 1</button>
                    <button className='exercise-button'>Sample exam 2</button>
                </div>
                </>
            )}

            {currentScreen === 'select_exam_section' && !isGenerating &&  (
                <>
                <CloseButton/>
                <div className='buttons-home'>
                    <h2>{exam_id_mapper[examID]}</h2>
                    {false && <button className='exercise-button disabled' onClick={()=>onSelectExamSection('full_exam')} disabled>Study a full test 🍰</button>}
                    <button className='exercise-button' onClick={()=>onSelectExamSection('reading')}>Reading 📖</button>
                    <button className='exercise-button' onClick={()=>onSelectExamSection('listening')} >Listening 👂</button>
                    <button className='exercise-button' onClick={()=>onSelectExamSection('speaking')}>Speaking 👄</button>
                    <button className='exercise-button' onClick={()=>onSelectExamSection('writing')}>Writing 📝</button>
                </div>
                </>
            )}

            {/* <READING> */}

            {currentScreen === 'reading_directions' && (
                <div>
                    <h2>Reading 📖</h2>
                    <CloseButton/>
                    <button className='exercise-button' onClick={()=>setCurrentScreen('reading_start')}>Ready</button>
                    <div className='speaking-reading-block'> 
                    <p><strong>Directions:</strong> In this section, you will be able to demonstrate your ability to understand academic passages in English. There are two passages in this section. You have 36 minutes to complete the entire section, so plan to spend about 18 minutes on
                    each passage and the accompanying questions. Most questions are worth one point, but the last question for each passage is worth more than one point. The directions for the last question indicate how many points you may receive.</p>
                </div> 
                <audio controls autoPlay onEnded={()=>setCurrentScreen('reading_start')}>
                        <source src={`${server_root}/data/reading/directions.mp3`}  type='audio/mp3'/>
                </audio>
              </div>             
            )}

            {currentScreen === 'reading_start' && readingData && (
                <>
                <CloseButton/>
                <div className='reading-container'>
                    <div className="question-container">
                        <div className="timer-progress">
                            {(showTimer || submissionDone) && (
                                <div className={getTimerClass(timeLeft)}>
                                    {!submissionDone && <button className="exercise-button smaller" onClick={() => setShowTimer(false)}>Hide Time</button>}
                                    {<FormatTime timeLeft={submissionDone ? readingFeedback.time_left : timeLeft}/>}
                                </div>
                            )}
                            {(!showTimer && !submissionDone) && <button className = 'exercise-button smaller' onClick={() => setShowTimer(true)}>Show Time</button>}
                            <div><strong>{currentReadingIndex * readingData.readings[0]?.questions.length + currentQuestionIndex + 1}/{readingData.questions_num}</strong></div>
                        </div>
                        <div className="question-content">
                            {renderQuestion(readingData.readings[currentReadingIndex].questions[currentQuestionIndex], currentQuestionIndex)}
                        </div>
                        <div>
                            <button className="nav-button" onClick={handlePreviousQuestion} disabled={currentQuestionIndex === 0 && currentReadingIndex === 0}>← Previous</button>
                            {submissionDone && <button className='nav-button' onClick={()=>setCurrentScreen('reading_overview')}>Overview</button>}
                            <button className="nav-button" onClick={handleNextQuestion} disabled={currentQuestionIndex === readingData.readings[readingData.readings.length - 1].questions.length - 1 && currentReadingIndex ===  readingData.readings.length - 1}>Next →</button>
                        </div>
                        {!submissionDone && <button className="submit-button" onClick={submitReadingAnswers}>Submit</button>}
                    </div>

                    <div className="reading-passage">
                        <h2 id='reading-header'>{readingData.readings[currentReadingIndex].title}</h2>
                        {readingData.readings[currentReadingIndex].paragraphs.map((paragraph, idx) => (
                            <p key={idx} id={`paragraph-${idx}`} className={(idx === readingData.readings[currentReadingIndex].questions[currentQuestionIndex]?.idx_target_paragraph) ? 'target-paragraph' : 'paragraph'}>{paragraph}</p>
                        ))}
                    </div>
                </div>
                </>
            )}
            {(currentScreen === 'reading_overview' && submissionDone && readingFeedback.feedback && !isGenerating) && (
                <>
                <CloseButton/>
                <div className='listening-overview'>
                    <h2>Reading Overview</h2>
                    <div className='scores-time-listening'>
                        <span><p>raw score: {readingFeedback.raw_score}/{readingFeedback.max_score}</p></span> 
                        <span><p>reading score: {readingFeedback.scale_score}/{30}</p></span>
                        <span><FormatTime timeLeft={submissionDone ? readingFeedback.time_left : timeLeft}/></span>
                    </div>
                    <button className='exercise-button' onClick={()=>resetToHome()}>Exit</button>
                    <button className='exercise-button' onClick={()=>onSelectExamSection('listening')}>Go listening</button>
                    <div className='listening-questions-grid'>
                        {readingFeedback.feedback.map((fb, readingIdx) => 
                            fb.map((f, qIdx) => (
                                <div key={qIdx} className='listening-question-card'>
                                    <div className='question-info'>
                                        <span>{readingIdx + 1}.{qIdx + 1}</span>
                                        <span>{f.is_ok ? <CorrectIcon /> : <IncorrectIcon />}</span>
                                    </div>
                                    <button
                                            onClick={() => {
                                                setCurrentReadingIndex(readingIdx);
                                                setCurrentQuestionIndex(qIdx);
                                                setCurrentScreen('reading_start');
                                            }}
                                    >
                                        Go
                                    </button>
                                </div>))
                        )}                        
                    </div>
                </div>
                </>
            )}

            {/* </READING> */}

            {/*  <LISTENING>  */}
            {currentScreen === 'listening_directions' && (
                <div>
                    <h2>Listening 👂</h2>
                    <CloseButton/>
                    <button className='exercise-button' onClick={()=>{
                        setCurrentScreen('listening_conversation_start');
                        startGeneralTimer();
                    }
                    }>Ready</button>
                    <div className='speaking-reading-block'> 
                    <p><strong>Directions:</strong> This part of the test will measure your listening ability when it comes to the conversations and lectures in academic settings. You will listen to a recording and then answer questions about it.</p>
                
                    <p>You will be able to take notes while listening, and you can listen to the recording <strong>only once</strong>. The questions must be answered in the presented order. During the exam, <strong>you will not be allowed to go back to the previous question</strong>.</p>
                
                    <p>The questions will be about the main idea and the supporting details. Some questions will be about the speaker’s purpose or attitude. Answer the questions based on what is stated or implied by the speaker.</p>
                
                    <p>Sometimes you will see this icon 🎧, it means that you will have to listen to a certain segment of the recording and answer a question about it.</p>
                </div> 
                <audio controls autoPlay>
                        <source src={`${server_root}/data/listening/directions.mp3`}  type='audio/mp3'/>
                </audio>
              </div>             
            )}

            {(currentScreen === 'listening_conversation_start' && selectedListeningEx) && (
                <div>
                    <CloseButton/>
                    {showLConversationDirections &&                        
                        <div>
                            <audio controls={false} autoPlay onEnded={()=>{
                                setTimeout(()=>setShowLConversationDirections(false), 1000) // wait for 1s before listening conv
                            }}>
                                <source src = {instructionsURL} type='audio/mp3'/>
                            </audio>

                        </div>}
                        {(submissionDone || isTesting) && <button className='exercise-button' onClick={()=>setCurrentScreen('listening_conversation_questions')}>Questions</button>}
                        {!showLConversationDirections &&                        
                        <div>
                            <h3>{selectedListeningEx.type === 'conversation' ? 'Conversation' : 'Lecture'}🔉</h3>
                            <div>
                                <audio controls={false} autoPlay onEnded={()=>{setCurrentScreen('listening_conversation_questions')}}>
                                    <source src = {audioLURLs[listeningExIdx]} type='audio/mp3'/>
                                </audio>
                                <div className='img-container'>
                                    <img src = {lectureImgURL} className = 'lecture-img' alt = 'lecture img'/>
                                </div>
                            </div>
                        </div>}
                </div>
            )}
            {(currentScreen === 'transcripts' && submissionDone && listeningData && listeningFeedback) && (
                <div className='transcripts-container'>
                    <CloseButton/>
                    <div className='transcript-selection-container'>
                        <button 
                            className='exercise-button' 
                            onClick={()=>setCurrentScreen('listening_overview')}
                            >
                                Overview
                        </button>
                        {listeningData && listeningData.map((ex, exIdx) => (
                        <div key = {exIdx} className='transcript-header'>                        
                            <div className='not-text-container'>
                                <div className='button-header'>
                                    <h4>{exIdx + 1}. {ex.type === 'conversation' ? 'Conversation' : 'Lecture'}</h4>
                                    <button
                                        className='exercise-button'
                                        onClick={()=>setSelectedTranscriptIdx(exIdx)}
                                    >
                                        Show transcript
                                    </button>
                                </div>
                                
                                <div>
                                    <audio controls>
                                        <source src={audioLURLs[exIdx]} type='audio/mp3'/>
                                    </audio>
                                </div>
                            </div>

                        </div>
                    ))}
                    </div>
                    <div className='transcript-content'>
                        {listeningData[selectedTranscriptIdx]?.conversation.map((c, msgIdx)=>(
                            <p key = {msgIdx} className='transcript-text'>
                                <strong>{c.role}: ({c.sex}):</strong> {c.text}
                            </p>
                        ))}
                    </div>
                    
                </div>
            )}

            {currentScreen === 'listening_overview' && (
                <>
                <CloseButton/>
                <ListeningOverview
                feedback={listeningFeedback}
                listeningData={listeningData}
                onSelectQ={(exIdx, qIdx)=>{
                    setListeningExIdx(exIdx);
                    setCurrentListeningQuestionIndex(qIdx);
                    setCurrentScreen('listening_conversation_questions');
                }}
                />
                </>
            )}

            {currentScreen === 'listening_conversation_questions' && selectedListeningQuestion && (
                <div className='question-container'> 
                {isTesting && <button onClick={fakeLSubmission}>fake submission</button>}
                    <CloseButton/>
                    <div className='question-ratio-timer'>
                        <div className='qr-fb-mark'>
                            <p><strong>{absQLIndex+1}/{listeningQuestionsNum}</strong></p>
                            {(submissionDone && listeningFeedback.fb) && <span className='correction-mark'>{listeningFeedback.fb[absQLIndex]?.is_correct ? <CorrectIcon/> : <IncorrectIcon/>}</span>}
                        </div>
                        <span style={{fontSize: '1.2rem'} }><strong>{listeningExIdx + 1}. {selectedListeningEx.type === 'conversation' ? 'Conversation' : 'Lecture'}</strong></span>
                        <div className={'timer-block'}>
                            <FormatTime timeLeft={submissionDone ? listeningFeedback?.time_left : timeLeft}/>
                        </div>
                    </div>
                    <div className='question-content'>
                        <p>{absQLIndex + 1}. {selectedListeningQuestion.question}</p>
                        {(selectedListeningQuestion.type === 'context_understanding' && contextAudioURL) && (
                            <>
                                <audio id = {`audio_q-${currentListeningQuestionIndex}`} >
                                    <source src={contextAudioURL}/>
                                </audio>
                                <button 
                                    id = {`play_button_q-${currentListeningQuestionIndex}`} 
                                    className='exercise-button'
                                    onClick={()=>{
                                        const audio = document.getElementById(`audio_q-${currentListeningQuestionIndex}`);
                                        const playButton = document.getElementById(`play_button_q-${currentListeningQuestionIndex}`);
                                        if (audio) {
                                            audio.play();
                                            if (!submissionDone) {
                                                playButton.disabled = true;
                                                playButton.className = 'exercise-button disabled'
                                            }
                                        }
                                    }}
                                    ><FontAwesomeIcon icon={faPlay}/>
                                </button>
                            </>
                        )}
                        {selectedListeningQuestion.type !== 'table' && selectedListeningQuestion.options?.map((option_txt, idx) => (      
                            <div key={idx}>
                                <input
                                type = {selectedListeningQuestion.answer.length > 1 ? 'checkbox' : 'radio'}
                                name={`listening_options_${currentListeningQuestionIndex}`}
                                value={idx}
                                checked={listeningUserAnswers[listeningTaskKey]?.[currentListeningQuestionIndex]?.includes(idx)}
                                onChange={() => handleListeningAnswerChange(idx)}
                                disabled={submissionDone}
                                />
                                <label style={{
                                    color: 
                                    (submissionDone && selectedListeningQuestion.answer.includes(idx) && 
                                    listeningUserAnswers[listeningTaskKey]?.[currentListeningQuestionIndex]?.includes(idx)) 
                                        ? 
                                        'green' // correct answer selected by the user
                                        : (submissionDone && selectedListeningQuestion.answer.includes(idx) &&
                                            !listeningUserAnswers[listeningTaskKey]?.[currentListeningQuestionIndex]?.includes(idx)) 
                                            ? 'red' // correct answer not selected by the user 
                                            : 'inherit' // default
                                    
                                }}>{String.fromCharCode(97 + idx)}: {option_txt}{listeningUserAnswers[listeningTaskKey]?.[currentListeningQuestionIndex]?.includes(idx) && <FormatIconListening/>
                                }</label>
                            </div>
                        ))}
                        {selectedListeningQuestion.type === 'table' && (
                            <div>
                                <table className='styled-table'>
                                    <thead>
                                        <tr>
                                            <th>Description</th>
                                            
                                            {Object.keys(selectedListeningQuestion.table)
                                                .filter( p => p !== 'desc')
                                                .map((tag, colIdx)=>(
                                                <th key={colIdx}>{tag}</th>
                                            ))}
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {/* iterate over descriptions descriptions values */}
                                        {selectedListeningQuestion.table.desc.map((text, rowIdx) => (
                                            <tr key = {rowIdx}>
                                                {/* place the description for each row */}
                                                <td>{text}</td>

                                                {Object.values(selectedListeningQuestion.table)
                                                    .slice(1)
                                                    .map((_, colIdx) => (
                                                        <td key={colIdx}>
                                                            <input
                                                                type = 'checkbox'
                                                                className='center-checkbox'
                                                                name = {`table_${rowIdx}_${colIdx}`}
                                                                checked = {listeningUserAnswers[listeningTaskKey][currentListeningQuestionIndex]?.some(tup=>(
                                                                    tup[0] === rowIdx && tup[1] === colIdx
                                                                )) }
                                                                onChange={()=>handleTableAnswerChange(rowIdx, colIdx)}
                                                                disabled = {submissionDone}
                                                            />
                                                        </td>
                                                    ))
                                                }
                                            </tr>
                                        ))} 
                                    </tbody>
                                </table>
                            </div>
                        )}
                        {submissionDone && selectedListeningQuestion.type === 'table' && (
                            <div>
                                <h4>Correction table</h4>
                                <table className='styled-table'>
                                <thead>
                                        <tr>
                                            <th>Description</th>
                                            
                                            {Object.keys(selectedListeningQuestion.table)
                                                .filter( p => p !== 'desc')
                                                .map((tag, colIdx)=>(
                                                <th key={colIdx}>{tag}</th>
                                            ))}
                                        </tr>
                                </thead>
                                <tbody>
                                    {selectedListeningQuestion.table.desc.map((text, rowIdx)=>(
                                        <tr key={rowIdx}>
                                            <td>{text}</td>

                                            {Object.values(selectedListeningQuestion.table)
                                            .slice(1)
                                            .map((_, colIdx) => (
                                                <td key={colIdx}>
                                                    {
                                                        listeningFeedback.fb[absQLIndex].gt_answer.some(tup => (tup[0] === rowIdx && tup[1] === colIdx)) ? <CorrectIcon/>  : <IncorrectIcon/>
                                                    }
                                                </td>
                                            ))
                                            }
                                        </tr>
                                    ))}
                                </tbody>

                                </table>
                            </div>
                        )
                        }
                    {submissionDone && listeningFeedback && 
                    <div className='padded-reasoning'>
                        <p><strong>Correction:</strong></p>
                    <p className='reasoning-text'>{selectedListeningQuestion.reasoning_answer}</p>
                    </div>
                    }
                    </div>
                    
                    <div className='listeningQ-button-container'>

                        {submissionDone && <button
                            className='nav-button'
                            onClick = {handlePrevListeningQuestion}
                            disabled = {currentListeningQuestionIndex === 0 && listeningExIdx === 0}// nothing selected so far for this question
                        >{'← Previous'}
                        </button>}
                        {submissionDone && listeningFeedback && (
                            <button className='exercise-button' onClick={()=>setCurrentScreen('listening_overview')}>Overview</button>
                        )}
                        <button
                            className='nav-button'
                            onClick = {handleNextListeningQuestion}
                            disabled = {!listeningUserAnswers[listeningTaskKey]?.[currentListeningQuestionIndex]?.length}// nothing selected so far for this question
                            style={absQLIndex + 1 ===  listeningQuestionsNum  ? {backgroundColor: 'green'} : {}}
                        >{(absQLIndex + 1 < listeningQuestionsNum) ? 'Next →' : 'Finish'}
                        </button>
                    </div>
                    

                </div>
            )}

            {/* /LISTENING */}

            {/* <SPEAKING> */}

            
            {currentScreen === 'speaking_directions' && (
                <div>
                    <CloseButton/>
                    <h2>Speaking 👄</h2>  
                    <div className='speaking-reading-block'>
                        <p><strong>Directions: </strong>This part of the test will measure your speaking ability. It will last around 20-30 minutes. You will answer 4 questions. The first question will be about a familiar topic, the other three will be about short conversations, lectures and reading passages. You can read and hear the lectures and paragraphs <strong>only once.</strong> You will see the time available for preparing the responses as well as the time to give your response on the screen. You have to stay within those time limits.
                        </p>
                    </div>
                    <audio autoPlay>
                        <source src={`${server_root}/data/speaking/directions.mp3`} type='audio/mp3'></source>
                    </audio>
                    <button 
                        className='exercise-button' 
                        onClick={()=>{
                            setSpeakingScreenIdx(p=>p+1);
                        }}>
                            Ready
                    </button>
                </div>
            )}
            {isTesting && (
                <>
                <p>speakingExIdx: {speakingExIdx}</p>
                <button onClick={()=>setSpeakingScreenIdx(p=>p+1)}>Continue</button>
                <button onClick={()=>setTimeLeft(p => p - 5)}>decrease time -= 5</button>
                <span>current screen: {currentScreen}</span>
                <button onClick={()=>{
                    setPaused(p=> !p);
                }}>pause</button>
                </>

            )}
            {currentScreen === 'speaking_task_directions' && (
                <div className='speaking-block'>
                    <CloseButton/>
                    <h3>Directions</h3>  
                    <h4>Task {speakingExIdx + 1}</h4>                  

                    <div className='speaking-reading-block'>
                        {speakingExIdx === 0 && (
                            <p>
                                You will now be asked a question about a familiar topic. After you hear the question, you will have 15 seconds to plan your response and 45 seconds to speak.
                            </p>
                        )}
                        {speakingExIdx === 1 && (
                            <p>
                                You will now read a short passage and then listen to a conversation on the same topic. You will then be asked a question about the passages. After you hear the question, you will have 30 seconds to prepare your response and 60 seconds to speak.
                                You have 45 seconds to read the passage below. You may begin reading now.
                            </p>
                        )}

                        {speakingExIdx === 2 && (
                            <p>
                                You will now read a short passage and then listen to a lecture on the same topic. You will then be asked a question about the passages. After you hear the question, you will have 30 seconds to prepare your response and 60 seconds to speak.
                                You have 45 seconds to read the passage below. You may begin reading now
                            </p>
                        )}    

                        {speakingExIdx === 3 && (
                            <p>
                                You will now listen to part of a lecture. You will then be asked a question about it. After 
                                you hear the question, you will have 20 seconds to prepare your response and 60 seconds to speak.
                            </p>
                        )} 
                    </div>    
                    <audio 
                        controls = {false}
                        autoPlay 
                        onEnded={()=>{
                            setSpeakingScreenIdx(p=>p+1);
                        }}
                    >
                        <source src={`${server_root}/data/speaking/${speakingExIdx}_directions.mp3`} type='audio/mp3'></source>
                    </audio>

                    <button 
                        className='exercise-button'
                        onClick={()=>{
                            setSpeakingScreenIdx(p=>p+1);
                        }}
                        >
                            Ready
                    </button>
                </div>
            )}

            {currentScreen === 'speaking_reading' && speakingData && (
                <div className='speaking-block'>
                    <CloseButton/>
                    <h3>Reading</h3>
                    <h4>Task {speakingExIdx + 1}</h4>
                    <h3>{speakingData[speakingExIdx]?.reading_title}</h3>
                    <p className='speaking-reading-block'>{speakingData[speakingExIdx]?.reading_txt}</p>
                    <button 
                        className='exercise-button'
                        onClick={()=>setSpeakingScreenIdx(p => p + 1)}
                    >
                            Ready
                        </button>
                    <div className={'timer-block'}>
                        <FormatTime timeLeft={timeLeft}/>
                    </div>

                </div>
            )}

            {currentScreen === 'speaking_listening' && (
                <div>
                    <CloseButton/>
                    <h3>{speakingExIdx === 1 ? 'Conversation' : 'Lecture'}</h3>
                    <h4>Task {speakingExIdx + 1}</h4>
                    <audio
                    id = 'speaking-listening-audio'
                    controls = {isTesting ? true : false}
                    onEnded={()=>{
                        // go to question
                        setSpeakingScreenIdx(p => p + 1);
                    }}
                    >
                        <source src = {`${server_root}/data/speaking/${speakingExamID}/${speakingExIdx}_conversation.mp3`} type='audio/mp3'></source>
                    </audio>

                    <audio // listening intro -> triggers listening
                    autoPlay
                    controls = {false} 
                    onEnded={()=>{
                        setTimeout(()=>{
                            const audio = document.getElementById('speaking-listening-audio');
                            audio && audio.play();                            
                        }, 1000);
                    }}
                    >
                        <source src = {`${server_root}/data/speaking/${speakingExIdx}_listening.mp3`} type='audio/mp3'></source>
                    </audio>
                </div>
            )}

            {currentScreen === 'speaking_question' && speakingData && (
                <div className='speaking-block'>    
                    <CloseButton/>
                    <h3>Question</h3>
                    <h4>Task {speakingExIdx + 1}</h4>                
                    <p className='speaking-reading-block'>{speakingData[speakingExIdx].question}</p>
                    

                    <audio // 'Now, answer the question' triggers 'question'
                    autoPlay
                    controls = {false} 
                    onEnded={()=>{
                        setTimeout(()=>{
                            const audio = document.getElementById('audio-question');
                            audio && audio.play();
                        }, 1000);
                    }}
                    >
                        <source src = {`${server_root}/data/speaking/question_intro.mp3`} type='audio/mp3'></source>
                    </audio>
                    
                    <audio // triggers preparation start
                        id = 'audio-question'
                        onEnded={()=>{
                            // Listen to beep
                            setTimeout(()=>{
                                const audio = document.getElementById('prep-start');
                                if (audio) {
                                    audio.play();
                                };                                
                            }, 1000);
                        }}
                    >
                        <source src={`${server_root}/data/speaking/${speakingExamID}/${speakingExIdx}_question.mp3`} type='audio/mp3'></source>
                    </audio>

                    <audio // triggers: 'prepare your response after beep'
                        id='prep-start' 
                        controls={false} 
                        onEnded={()=>{
                        setTimeout(()=>{
                            const beep = document.getElementById('beep');
                            if (beep) {
                                beep.play();
                                setTimeLeft(preparation_times[speakingExIdx]);
                            };
                        }, 500) // trigger beep after 500ms
                        }}
                    >
                        <source src={`${server_root}/data/speaking/start_response_prep.mp3`} type='audio/mp3'/>
                    </audio>
                    
                    <audio // triggers beep
                        id='beep' 
                        controls={false} 
                        onEnded={()=>{
                        startGeneralTimer();
                        setSpeakingScreenIdx(p=>p+1); // move to 'prep'
                        }}
                    >
                        <source src={`${server_root}/data/speaking/beep.mp3`} type='audio/mp3'/>
                    </audio>

                </div>
            )}

            {currentScreen === 'speaking_prep' && (
                <div className='speaking-block'>
                    <CloseButton/>
                    <h3>Preparation</h3>
                    <h4>Task {speakingExIdx + 1}</h4>
                    <p className='speaking-reading-block'>{speakingData[speakingExIdx].question}</p>
                    <div className={'timer-block'}>
                        <FormatTime timeLeft={timeLeft}/>
                    </div>
                
                </div>
            )}

            
            
            {currentScreen === 'speaking_record' && (
                <div className='speaking-block'>
                    <CloseButton/>
                    <h3>Speak</h3>
                    <h4>Task {speakingExIdx + 1}</h4>
                    <audio 
                        id='beep' 
                        controls={false} 
                        onEnded={()=>{
                        startGeneralTimer();
                        startRecording();
                        // start recording the user. 
                    }}>
                        <source src={`${server_root}/data/speaking/beep.mp3`} type='audio/mp3'/>
                    </audio>

                    <audio controls={false} autoPlay onEnded={()=>{
                        setTimeout(()=>{
                            const beep = document.getElementById('beep');
                            beep && beep.play();

                        }, 1000) // trigger beep after 1 seconds.  
                    }}>
                        <source src={`${server_root}/data/speaking/start_recording.mp3`}/>
                    </audio> 
                    <p className='speaking-reading-block'>
                            {speakingData[speakingExIdx].question}
                    </p>
                    
                    {speakingIsRecording && (
                    <>
                        <div className={'timer-block'}>
                        <span><FontAwesomeIcon icon={faRecordVinyl} style={{color: 'red'}}/> {'    '}</span>
                          <FormatTime timeLeft={timeLeft}/>
                        </div>
                    
                        <button 
                        className='exercise-button'
                        onClick={() => {
                            stopRecording(); // stop recording and save answer
                            setSpeakingScreenIdx(p=>p+1); 
                        }}
                    >
                        Done
                    </button>
                    </>
                    )}
                </div>
            )}

            {currentScreen ===  'speaking_exercise_switch' && (
                <div className='speaking-block'>
                    <CloseButton/>
                    <p>Speking exercise {speakingExIdx + 1}/4 finished 🎉🥳</p>
                    <button 
                        className='exercise-button'
                        onClick={()=>{
                            (speakingExIdx < speakingData.length - 1) && setSpeakingExIdx(p => p + 1)
                        }}>
                            Go next! 🚀
                        </button>
                </div>
            )}

            {currentScreen === 'speaking_overview' && (
                <div className='speaking-block'>
                    <CloseButton/>
                    <h2>Overview</h2>
                    <div className='button-header'>
                        <button 
                            className='exercise-button'
                            onClick={()=>onSelectExamSection('writing')}
                            >
                                Go writing
                        </button>
                        <button className='exercise-button' onClick={resetToHome}>Exit</button>
                    </div>
                    {speakingData.map((ex, exIdx) => (
                        <div key={exIdx} >
                            {/* <hr style={{ border: '1px solid #ffd54f' }} />                             */}
                            <h3>Task {exIdx + 1}</h3>
                            {ex.reading_txt && (
                                <div className='speaking-reading-block'>
                                    <h4><strong>Reading: {ex.reading_title}</strong></h4>
                                    <p>{ex.reading_txt}</p>
                                </div>
                            )}  
                            {ex.audio && (
                                <>
                                <div className='speaking-reading-block'>
                                    <h4>Lecture</h4>
                                    <audio controls>
                                        <source src={`${server_root}/data/speaking/${speakingExamID}/${exIdx}_conversation.mp3`} type='audio/mp3'/>
                                    </audio>
                                    <h4><strong>{ex.type === 'conversation' ? 'Conversation' : 'Lecture'} transcript</strong></h4>
                                    {ex.audio.map((msg, msgIdx) => (
                                        <p key={msgIdx}><strong>{msg.role}:</strong> {msg.text}</p>
                                    ))}
                                </div>
                                </>
                            )}
                            <div className='speaking-reading-block'> 
                                <h4><strong>Question</strong></h4>
                                <p>{ex.question}</p>
                            </div>
                            
                            <div className='speaking-reading-block'>
                                <h4>Your answer</h4>
                                <audio controls>
                                    <source src={`${server_root}/data/speaking/${speakingExamID}/${exIdx}_user_response.webm`} type='audio/webm'/>
                                </audio>
                                {userTranscripts && userTranscripts?.length > 0 && (
                                    <>
                                        <h4>Transcript</h4>
                                        <p>{userTranscripts[exIdx]}</p>
                                    </>
                                )}
                            </div>


                            {ex.answer && (
                                <div className='speaking-reading-block'>
                                    <h4><strong>Sample answer</strong></h4>
                                    <p>{ex.answer}</p>
                                </div>
                            )}
                        </div>
                    ))}

                </div>
            )}


            {/* </SPEAKING> */}


            {/* <WRITING> */}


            {currentScreen === 'writing_ex_select' && (
                <>
                <CloseButton/>
                <h2>Writing 📝</h2>
                <div className='buttons-home'>
                    <button className="exercise-button" onClick={()=>{
                        setWritingScreenIndex(1)
                        }}>
                        1. Integrated question
                    </button>
                    <button className="exercise-button" onClick={()=>{
                        setWritingScreenIndex(6);
                        }}>
                        2. Academic discussion 
                    </button>
                </div>
                </>
            )}

            {currentScreen === 'topicSelect' && (
                <div className="topic-select">
                    <button className="topic-button" onClick={() => onSelectTopic('science')}>🔬 Science</button>
                    <button className="topic-button" onClick={() => onSelectTopic('technology')}>🤖 Technology</button>
                    <button className="topic-button" onClick={() => onSelectTopic('history')}>🏛 History</button>
                    <button className="topic-button" onClick={() => onSelectTopic('art')}>🎨 Art</button>
                    <button className="topic-button" onClick={() => onSelectTopic('culture')}>🌍 Culture</button>
                    <button className="topic-button" onClick={() => onSelectTopic('')}>🎲 Random</button>
                    <CloseButton/>
                </div>
            )}


            {currentScreen === 'writing_ex1_directions' && (
                <div>
                    <CloseButton/>
                    <button className='exercise-button' onClick={()=>{
                        setWritingScreenIndex(p=>p+1);
                    }}>Ready</button>
                    <p className='speaking-reading-block'>For this task, you will read a passage and listen to a lecture about an academic topic.
                    You may take notes during this time. After the passages have finished, you will then be asked a question about them. After the question, you will have 20 minutes to write your response. Effective responses are usually between 200 to 350 words. You may look at the reading passage and your notes as you write. Keep in mind that the question will not ask for your opinion.
                    You have 3 minutes to read. You will begin reading on the next screen. Practise make perfect,happy exam!</p>
                    <audio autoPlay controls = {false}>
                        <source src={`${server_root}/data/writing/0_directions.mp3`} type='audio/mp3'/>
                    </audio>
                </div>
            )}

            {currentScreen === 'writing_ex1_reading' && writingData.ex1 && (
                <div>
                    <CloseButton/>
                    <div  className={getTimerClass(readingTimeLeft)}>
                        <FormatTime timeLeft={readingTimeLeft}/>
                    </div>
                    
                    <button className='exercise-button' onClick={() => {
                        setWritingScreenIndex(p=>p+1);
                    }}>Ready</button>
                    <div className="speaking-reading-block speaking-user-answer">{writingData.ex1.reading}</div>
                </div>
            )}

            {currentScreen === 'writing_ex1_lecture' && (
                <div>
                    <CloseButton/>
                    <div className='speaking-reading-block'>Now listen to part of a lecture on the same topic you just read about. After you listen to the passage, answer the question.</div>
                    <audio 
                        id = 'lecture-audio' 
                        controls={isTesting ? true : false} 
                        onEnded={() => {
                            setWritingScreenIndex(p=>p+1);
                        }}
                    >
                        <source src={`${server_root}/data/writing/ex1/${writingData.ex1.task_id}/lecture.mp3`} type='audio/mp3' />
                    </audio>
                    <audio
                        autoPlay
                        controls = {false}
                        onEnded={() => {
                            setTimeout(() => {
                                const audio = document.getElementById('lecture-audio');
                                audio && audio.play();
                            }, 1000);
                        }}
                    >
                        <source src={`${server_root}/data/writing/0_listening_intro.mp3`} type='audio/mp3' />
                    </audio>
                </div>
            )}

            {currentScreen === 'writing_ex1_question' && (
              <div className='speaking-reading-block'>
                <CloseButton/>
                <p>Summarize the points made in the lecture, being sure to explain how they cast doubt on the specific points made in the reading passage</p>
                <audio 
                    autoPlay 
                    controls = {false}
                    onEnded={() => {
                        setTimeout(() => {
                            setWritingScreenIndex(p=>p+1);
                        }, 1500);
                    }}
                >
                    <source src={`${server_root}/data/writing/0_question.mp3`} type='audio/mp3' />
                </audio>
              </div>  

            )}

            {currentScreen === 'writing_ex1_writing' && writingData.ex1 &&(
                <div className='answer-reading-block'>
                    <CloseButton/>
                    {isTesting && <button onClick={testIncreaseDeltaLeft}>time test</button>}
                    {isTesting && <button onClick={()=>setTimeLeft(1200)}>time reset</button>}
                    <p className='speaking-reading-block'>Summarize the points made in the lecture, being sure to explain how they cast doubt on the specific
                    points made in the reading passage.</p>
                    <div className='just-row-items'>
                        <p style={{marginRight: '20px'}}>Word count: <strong>{wordCount}</strong></p>
                        <div className={getTimerClass(timeLeft)}>
                            <FormatTime timeLeft={timeLeft}/>
                        </div>
                        <button className='exercise-button' onClick={() => setShowReadingDuringWriting(p => !p)}>Show reading</button>
                    </div>
                    <textarea
                        rows='30'
                        cols='100'
                        value={writingAnswer[1]}
                        onChange={(e) => handleInputChange(e, 1)}
                        placeholder='You have 20 mins to write. Good luck!'
                    />
                    {showReadingWhileWriting && (
                        <div className="speaking-reading-block speaking-user-answer" dangerouslySetInnerHTML={{'__html': writingData.ex1.reading}} />
                    )}
                    <button className="submit-button" onClick={() => onSubmitWriting(1)}>Submit</button>

                </div>
            )}

            {currentScreen === 'writing_ex1_overview' && (
                <div>
                    <h2>Overview</h2>
                    <CloseButton/>
                    <div className={getTimerClass(timeLeft)}>
                        <FormatTime timeLeft={timeLeft}/>
                    </div>
                    <button className='exercise-button' onClick={resetToHome}>Exit</button>
                    <button 
                        className='exercise-button'
                        onClick={()=>setWritingScreenIndex(6)}
                        >Next exercise</button>
                    
                    {writingAnswer[1] && <>
                        <h4>Your answer</h4>
                        <p className='speaking-reading-block speaking-user-answer'>{writingAnswer[1]}</p>
                        {writingData.ex1.answer && 
                        <>
                            <h4>Sample answer Ex 1</h4>
                            <p className='speaking-reading-block speaking-user-answer'>{writingData.ex1.answer}</p>
                        </>
                        }
                    </>}
                </div>
            )}

            {currentScreen === 'writing_ex2_overview' && (
                <div>
                    <h2>Overview</h2>
                    <CloseButton/>
                    <div className={getTimerClass(timeLeft)}>
                        <FormatTime timeLeft={timeLeft}/>
                    </div>
                    <button className='exercise-button' onClick={resetToHome}>Exit</button>
                    
                    {writingAnswer[2] && <>
                        <h4>Your answer</h4>
                        <p className='speaking-reading-block speaking-user-answer'>{writingAnswer[2]}</p>
                        {writingData.ex1.answer && <>
                            <h4>Sample answer Ex 2</h4>
                            <p className='speaking-reading-block speaking-user-answer'>{writingData.ex2.answer}</p>
                        </>
                        }
                    </>}
                </div>
            )}


            {/* writing task2  */}
            {currentScreen === 'writing_ex2' && writingData.ex2 && (
                <div className="discussion-task">
                    <div className="discussion-column1">
                        <h3>Instructions:</h3>
                        <p>Your professor is teaching a class on {writingData.ex2.class_topic}.</p>
                        <p>Write a post responding to the professor's question. In your response, you should do the following:</p>
                        <ul>
                            <li>Express and support your opinion.</li>
                            <li>Make a contribution to the discussion in your own words.</li>
                        </ul>
                        <p>An effective response will contain at least 100 words.</p>
                        <p><strong>{writingData.ex2.professor.name}:</strong> {writingData.ex2.professor.text}</p>
                    </div>
                    <div className="discussion-column2">
                        <div className="discussion-row">
                            <div className="discussion-student"><strong>{writingData.ex2.student1.name}:</strong></div>
                            <div className="discussion-text">{writingData.ex2.student1.text}</div>
                        </div>
                        <div className="discussion-row">
                            <div className="discussion-student"><strong>{writingData.ex2.student2.name}:</strong></div>
                            <div className="discussion-text">{writingData.ex2.student2.text}</div>
                        </div>
                        <div className="writing-area">
                            <div className={getTimerClass(timeLeft)}>
                                <FormatTime timeLeft={timeLeft}/>
                            </div>
                            <p>Word count: <strong>{wordCount}</strong></p>
                            <textarea
                                rows='15'
                                cols='80'
                                value={writingAnswer[2]}
                                onChange={(e) => handleInputChange(e, 2)}
                                placeholder='You have 10 minutes to read and write. Good luck!'
                            />
                            <button className="submit-button" onClick={() => onSubmitWriting(2)}>Submit</button>
                        </div>
                    </div>
                    <CloseButton/>
                </div>
            )}

            {/* </ WRITING> */}

        {isGenerating && (
            <div className="loading-container">
                <div className="spinner"></div>
                <p>Loading content...</p>
            </div>
        )}
        </div>
    );
}

export default App;
